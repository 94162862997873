import _ from "lodash";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
// import tachyons from 'tachyons-js';
// global.tach = tachyons;
import Layout from "../components/Layout";
import artistsImage from "../img/resources-white.svg";
import brainImage from "../img/brain.png";
import exhibitionImage from "../img/exhibition.png";
import communityImage from "../img/hands-plant.svg";
import vrHeadImage from "../img/vr-head-white.svg";
import ty from "../styled-tachyons";
import {
  BodyText,
  Button,
  ColHalf,
  ColQuarter,
  DarkGraySection,
  FlexRow,
  GraySection,
  Image,
  RedSection,
  rem,
  Row,
  SectionHeader,
  SubHeader,
  RichText,
  nsQuery,
  lQuery,
} from "../styles";
import { Hero } from "../components/Hero";

export const Em = styled.div`
  ${ty`red`}
  font-family: nunito, san-serif;
`;

const ProfilePhoto = styled.div`
  ${ty`br-pill overflow-hidden center`};
  max-width: ${rem(131)};
  ${Image} {
    width: 100%;
  }
`;
const GetToKnowUsSection = styled(DarkGraySection)`
  ${ty`tc`}

  ${SectionHeader} {
    ${ty`washed-red`}
  }

  ${SubHeader} {
    ${ty`ttu`}
  }

  ${Row} {
    ${ty`center`}
    max-width: ${rem(714)};
    ${ProfilePhoto} {
      max-width: ${rem(131)};
      max-height: ${rem(131)};
    }
  }
`;

const WhyArsSection = styled(GraySection)`
  ${Image} {
    max-width: ${rem(215)};
    ${nsQuery} {
      max-width: ${rem(450)};
    }
  }

  ${ColHalf}:first-child {
    order: 1;
    ${nsQuery} {
      order: 0;
    }
  }

  ${FlexRow} {
    ${ty`items-center`}
  }
`;

const WhatWeDoSection = styled(RedSection)`
  ${ty`white`}
  ${Image} {
    ${ty`w-auto db center mw-100`}
  }

  ${Button} {
    ${ty`mt4`}
  }

  ${ColQuarter} {
    ${ty`ph5 mw6 mw-100-l`}
    ${SubHeader} {
      ${ty`pb0`}
    }
    ${BodyText} {
      ${ty`mt0`}
    }
  }
`;

const IndexPageStyle = styled.div`
  ${SectionHeader} {
    ${ty`f2-ns`}
  }
`;

const PositionTitle = styled.h4`
  ${ty`red fw4`}
`;

const PositionsHeader = styled.h2`
  ${ty`white`}
  font-family: nunito, sans-serif;
`;

const CofoundersSection = styled.section`
  ${RichText} {
    ${ty`mt3`}
  }

  ${ty`mh1-l`}

  ${FlexRow} {
    ${ty`justify-between-ns`}
  }
  ${lQuery} {
    ${ColHalf} {
      max-width: 41%;
    }
  }
`;

export const IndexPageTemplate = ({
  hero,
  heroVideoUrl,
  committee,
  cofounders,
}) => {
  committee = committee || [];
  hero = hero || {};
  return (
    <IndexPageStyle>
      <Hero imageUrl={hero.image} videoUrl={hero.videoUrl} />
      <WhatWeDoSection>
        <SectionHeader>What we do</SectionHeader>
        <BodyText
          fontSize="medium"
          css={`
            ${ty`mw7 center`}
          `}
        >
          ARS provides technology and resources to artists whose interest aligns
          with these new mediums, allowing them to develop an alternative and
          complimentary means of expression to their existing practice.
        </BodyText>
        <Button center to="/get-involved">
          Get Involved
        </Button>
        <Row>
          <FlexRow ty="flex-wrap mt5">
            <ColQuarter>
              <Image src={vrHeadImage} />
              <SubHeader>Technology</SubHeader>
              <BodyText fontSize="small">
                ARS provides high quality cutting-edge technology that fits with
                the vision of the artists.
              </BodyText>
            </ColQuarter>
            <ColQuarter>
              <Image src={exhibitionImage} />
              <SubHeader>Exhibition</SubHeader>
              <BodyText fontSize="small">
                Each completed project is presented at the ARS studio in Eagle
                Rock California or at a Los Angeles arts institution.
              </BodyText>
            </ColQuarter>
            <ColQuarter>
              <Image src={artistsImage} />
              <SubHeader>Resources</SubHeader>
              <BodyText fontSize="small">
                ARS recruits advisors and experts in a variety of fields to
                assist artists in the development of their projects.
              </BodyText>
            </ColQuarter>
            <ColQuarter>
              <Image src={communityImage} />
              <SubHeader>Community</SubHeader>
              <BodyText fontSize="small">
                As a 501(c)(3) ARS expands the community’s understanding of
                technology and art in society.
              </BodyText>
            </ColQuarter>
          </FlexRow>
        </Row>
      </WhatWeDoSection>
      <WhyArsSection>
        <FlexRow>
          <ColHalf>
            <Image src={brainImage} />
          </ColHalf>
          <ColHalf>
            <SectionHeader>Why ARS?</SectionHeader>
            <BodyText noCenter>
              Founded in 2017 by Brent Imai and Frank Masi, Art Reality Studio
              (ARS) is a 501 (c)(3) non-profit public charity dedicated to
              connecting artists to technology. ARS asks: What happens when
              artists are given access to the latest cutting-edge technology?
            </BodyText>
            <BodyText noCenter>
              The 21st century has ushered in a world with a rapidly changing
              technological landscape that has the potential to impact our
              communities and society more quickly and to a greater degree than
              ever before. Through the development of new economies to the
              destruction of democratic processes, technology is both humanity's
              greatest asset and greatest threat.
            </BodyText>
            <BodyText noCenter>
              ARS offers artists the opportunity to employ the latest
              technologies as a means to understand the human experience in this
              new reality. Known to be the most innovative problem-solvers,
              artists have the ability to illuminate technology’s potential
              impact on the world and demonstrate how we can understand our
              place as humans in this ever-evolving ecosystem.
            </BodyText>
          </ColHalf>
        </FlexRow>
      </WhyArsSection>
      <GetToKnowUsSection>
        <SectionHeader>Get to Know Us</SectionHeader>
        <CofoundersSection>
          <PositionsHeader css={ty`mb3`}>Board Members</PositionsHeader>
          <FlexRow>
            {cofounders.map(({ name, position, bio_html, image }, i) => {
              let imageProps = _.isString(image)
                ? { src: image }
                : { fluid: image?.childImageSharp?.fluid };

              let styleStr = "mt4";
              if (cofounders.length % 2 === 1 && i === cofounders.length - 1) {
                styleStr += " center";
              }

              return (
                <ColHalf key={i} css={ty`${styleStr}`}>
                  <ProfilePhoto>
                    <Image {...imageProps} />
                  </ProfilePhoto>
                  <SubHeader>{name}</SubHeader>
                  <PositionTitle>{position}</PositionTitle>
                  <RichText
                    dangerouslySetInnerHTML={{ __html: bio_html }}
                  ></RichText>
                </ColHalf>
              );
            })}
          </FlexRow>
        </CofoundersSection>

        <PositionsHeader css={ty`mt4 mt6-ns mb3`}>
          Technical and Administrative Advisory Committee
          <br />
          (TAAC)
        </PositionsHeader>
        <FlexRow ty="flex-wrap">
          {committee.map(({ name, position, affiliation }, i) => {
            return (
              <ColQuarter key={i} css={ty`mt4`}>
                <SubHeader>{name}</SubHeader>
                <PositionTitle>{position}</PositionTitle>
                <PositionTitle>{affiliation}</PositionTitle>
              </ColQuarter>
            );
          })}
        </FlexRow>
      </GetToKnowUsSection>
    </IndexPageStyle>
  );
};

const IndexPage = ({ data }) => {
  // console.log({ data });
  return (
    <Layout>
      <IndexPageTemplate {...data.markdownRemark.frontmatter} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          videoUrl
        }

        cofounders {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          position
          bio_html
        }
        committee {
          name
          position
          affiliation
        }
      }
    }
  }
`;
