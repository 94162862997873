import _ from "lodash";
import React from "react";
import styled from "styled-components";

import tubeImg from "../img/vr-tubes.png";
// import bgImage from "../img/laptop.svg";
import ty from "../styled-tachyons";
import { useWindowSize } from "../hooks/useWindowSize";
import {
  ArrowButton,
  BodyText,
  ColQuarter,
  ColThreeQuarters,
  FlexRow,
  Image,
  PageHeader,
  Section,
} from "../styles";
import { Em } from "../templates/index-page";
import playIcon from "../img/icon-play.svg";

const VideoPlaceholder = styled(Image)`
  position: relative;
  &:after {
    cursor: pointer;
    text-align: center;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    font-size: 50px;
    color: red;
    content: " ";
    background-image: url(${playIcon});
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 29%;
  }
`;
export const HeroSection = styled(Section).attrs(() => ({ full: true }))`
  ${ty`bg-near-black white ph0-l`}
  ${ArrowButton} {
    ${ty`flex`}
  }
`;
/* background: url(${bgImage}) no-repeat center; */

export function Hero({ imageUrl, videoUrl }) {
  // console.log({ imageUrl, videoUrl });
  let imageProps = false;
  if (imageUrl) {
    if (_.isString(imageUrl)) {
      imageProps = {
        src: imageUrl,
      };
    } else if (_.get(imageUrl, "childImageSharp.fluid")) {
      imageProps = {
        fluid: _.get(imageUrl, "childImageSharp.fluid"),
      };
    }
  }

  if (!imageProps) {
    imageProps = {
      src: tubeImg,
    };
  }

  let [videoPlaying, setVideoPlaying] = React.useState(false);
  let hasVideo = !!videoUrl;

  let toggleVideo = React.useCallback(() => setVideoPlaying(!videoPlaying), [
    videoPlaying,
  ]);

  let heroContent;

  let heroContentContainerRef = React.createRef();
  let windowSize = useWindowSize();
  let [containerSize, setContainerSize] = React.useState({
    width: 0,
    height: 0,
  });

  let imageStyle = {};
  let imageOnClick;
  let videoEmbed;
  if (!videoPlaying) {
    if (hasVideo) {
      imageOnClick = toggleVideo;
    }
  } else {
    if (videoUrl) {
      let videoObj = parseVideoUrl(videoUrl, {
        autoplay: true,
      });
      if (videoObj.src) {
        // console.log("render at", containerSize);
        videoEmbed = (
          <iframe
            src={videoObj.src}
            title="ARS - Art Reality Studio"
            frameBorder="0"
            width={containerSize.width}
            height={containerSize.height}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        );
        imageStyle.visibility = "hidden";
      }
    }
  }

  let [imageLoaded, setImageLoaded] = React.useState(false);
  heroContent = (
    <>
      <VideoPlaceholder
        full
        {...imageProps}
        onLoad={() => {
          setImageLoaded(true);
        }}
        onClick={imageOnClick}
        style={imageStyle}
      />
      {videoEmbed}
    </>
  );

  React.useEffect(() => {
    if (heroContentContainerRef.current) {
      let bounding = heroContentContainerRef.current.getBoundingClientRect();
      // console.log({ bounding });
      setContainerSize((size) => {
        if (size.width !== bounding.width || size.height !== bounding.height) {
          return {
            width: bounding.width,
            height: bounding.height,
          };
        }
        return size;
      });
    }
    return () => {
      // console.log("unmount?");
    };
  }, [windowSize, heroContentContainerRef, imageLoaded, setContainerSize]);

  return (
    <HeroSection>
      <FlexRow>
        <ColQuarter
          css={`
            ${ty`ph4 flex-ns flex-column-ns justify-center-ns mb4 mb0-l`}
          `}
        >
          <PageHeader style={{ fontFamily: "prompt, sans-serif" }}>
            ARS is an <Em>Art Practice</Em>
          </PageHeader>
          <BodyText>
            A group of art supporters, art advocates, collectors, enablers, curators, and educators who want to see what happens when artists are given cutting edge tools to create new work.
          </BodyText>
          <ArrowButton center ty="red center" to="/artists">
            View Projects
          </ArrowButton>
        </ColQuarter>
        <ColThreeQuarters
          css={`
            ${ty`pa0 relative`}
          `}
          ref={heroContentContainerRef}
        >
          {heroContent}
        </ColThreeQuarters>
      </FlexRow>
    </HeroSection>
  );
}

function parseVideoUrl(url, options = {}) {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );
  let type;
  if (RegExp.$3.indexOf("youtu") > -1) {
    type = "youtube";
  } else if (RegExp.$3.indexOf("vimeo") > -1) {
    type = "vimeo";
  }
  let videoId = RegExp.$6;
  let src;
  if (type === "youtube") {
    src = "//www.youtube.com/embed/" + videoId;
  } else if (type === "vimeo") {
    src = "//player.vimeo.com/video/" + videoId;
  }

  if (options.autoplay) {
    src = `${src}?autoplay=1`;
  }

  return {
    type: type,
    id: videoId,
    src,
  };
}
